<template lang="pug">
    .main-wrapper.parceiro-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Parceiros</b>

        Panel.my-2(header="Filtros" :toggleable="true" :collapsed="windowInnerWidth < 576" mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-4
                    label.form-label Nome fantasia:
                    .p-inputgroup
                        InputText(placeholder="Nome fantasia"
                            @keyup.enter.native="applyFilters()"
                            @input="(val) => val || applyFilters()"
                            v-model="filters.nm_fantasia"
                        )
                        Button(icon="jam jam-search" @click="applyFilters()")

                .p-col-12.p-md-4
                    label.form-label CNPJ:
                    .p-inputgroup
                        InputText(placeholder="__.___.___/____-__"
                            v-model="filters.nr_cnpj"
                            @keyup.enter.native="applyFilters()"
                            :autoClear="false")
                        Button(icon="jam jam-search" @click="applyFilters()")
                .p-col-12.p-md-4
                    label.form-label Status:
                    Dropdown(
                        v-model='filters.ie_habilitado'
                        :options='filtersOptions.ie_habilitado'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )

        .ta-right.my-2
            Button(label="Adicionar" icon="jam jam-plus" @click="$router.push('/parceiro/salvar/0/')")

        ProgressBar(v-if="waiting" mode="indeterminate")
        div(v-else-if="! list.length")
            p.ta-center.text-secondary(style="margin-top: 40px;") Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style="position: relative")
                            .ta-left
                                p <b>Nome fantasia:</b> {{ props.data.nm_fantasia }}
                                p <b>Razão social:</b> {{ props.data.nm_razao_social }}
                                p <b>CNPJ:</b> {{ props.data.nr_cnpj_f }}
                                p <b>Telefone:</b> {{ props.data.nr_telefone_f }}

            Paginator.dataview(
                :rows="paginator.per_page"
                :first="(paginator.page - 1) * paginator.per_page"
                :totalRecords="paginator.count"
                @page="onPage($event)"
                )

            Panel.datatable(header="Lista de Empresas")
                Paginator.mb-1(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                    )
                DataTable(:value="list")

                    Column(headerStyle="width: 4em;" bodyStyle="text-align: center;")
                        template(#body="props")
                            ProgressSpinner.progress-small(v-if="desabilitarId === props.data.id" strokeWidth="6")
                            .status-indicator(v-else :class="{ off: ! props.data.ie_habilitado }" @click="desabilitar(props.data)")
                                i.jam(:class="{ 'jam-check': props.data.ie_habilitado, 'jam-minus': !props.data.ie_habilitado }")
                    Column(headerStyle="width: 30%;" field="nm_fantasia" header="Nome Fantasia")
                    Column(headerStyle="width: 30%;" field="nm_razao_social" header="Razão social")
                    Column(headerStyle="width: 20%;" field="nr_cnpj" header="CNPJ" bodyStyle="text-align: center")
                    Column(headerStyle="width: 20%;" field="nr_telefone_f" header="Telefone" bodyStyle="text-align: center")
                    Column(headerStyle="width: 20%;" header="Ações" bodyStyle="text-align: center")
                        template(#body="props")
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/parceiro/salvar/${ props.data.id }/`)"
                                )

                Paginator(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                    )

</template>

<style lang="scss">
    .parceiro-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
        .progress-small {
            width: 25px;
            height: auto;
        }
    }
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import Dropdown from 'primevue/dropdown'
    import InputMask from "primevue/inputmask"
    import InputText from "primevue/inputtext"
    import Paginator from "primevue/paginator"
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from "primevue/progressspinner"
    import Tooltip from "primevue/tooltip"

    import Parceiro from "@/middleware/controllers/Parceiro"
    import wsConfigs from "@/middleware/configs"
    import {fixTelefoneFormat} from "@/utils"

    export default {
        components: { Button, Column, DataTable, DataView, InputText, InputMask,
            Paginator, Panel, ProgressBar, ProgressSpinner, Tooltip, Dropdown },
        directives: { tooltip: Tooltip },
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'parceiro') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created() {
        
            // this.applyFilters()
        },
        data() {
            return {
                list: [],
                waiting: false,
                desabilitarId: null,
                windowInnerWidth: window.innerWidth,
                filters: { 
                    nm_fantasia: "", 
                    nr_cnpj: "",
                    ie_habilitado: 'true'
                },
                filtersOptions: {
                    ie_habilitado: [
                        { text: "TODOS", value: null },
                        { text: "Ativos", value: 'true' },
                        { text: "Inativos", value: 'false' },
                    ]
                },
                paginator: {
                    count: 0,
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                }
            }
        },
        methods: {
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) {
                    this.$router.replace( { query: { pg: this.paginator.page } } )
                }
                Object.keys(this.filters).forEach(key => { if (this.filters[key]) params[key] = this.filters[key] })
                this.getList(params)
            },
            getList(params) {
                this.waiting = true
                Parceiro.findAll(params).then(response => {
                    this.waiting = false
                    this.paginator.count = response.data.count
                    this.list = response.data.results
                    this.list.forEach(parceiro => {
                        if (parceiro.nr_cnpj) {

                            parceiro.nr_cnpj_f = parceiro.nr_cnpj.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        }
                        if (parceiro.nr_telefone) {
                            parceiro.nr_telefone_f = fixTelefoneFormat(parceiro.nr_telefone)
                        }
                    })
                })
            },
            desabilitar(parceiro) {
                this.desabilitarId = parceiro.id
                const dataSend = { id: parceiro.id, ie_habilitado: !parceiro.ie_habilitado }
                Parceiro.save(dataSend).then(response => {
                    this.desabilitarId = null
                    if ([200, 201].includes(response.status)) {
                        parceiro.ie_habilitado = !parceiro.ie_habilitado
                        this.$toast.success(
                            `Parceiro ${parceiro.nm_fantasia} foi ${parceiro.ie_habilitado ? 'habilitado' : 'desabilitado'}`
                        )
                    }
                })
            }
        }
    }
</script>