import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find('/webcli/parceiro-medclub/', params)
const find = (id) => GenericDAO.find(`/webcli/parceiro-medclub/${ id }/`)
const remove = (id) => GenericDAO.remove(`/webcli/parceiro-medclub/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/webcli/parceiro-medclub/`, dataSend)

export default {
	find,
	findAll,
	remove,
	save
}